<template>
  <el-dialog width="1160px" :visible.sync="dialogVisible">
    <template #title>
      <div class="title">{{ canEdit ? title : "文件预览与下载" }}</div>
    </template>
    <template #footer>
      <div class="bottom-btn">
        <el-button
          v-if="canEdit"
          @click="addOtherType"
          type="success"
          icon="el-icon-circle-plus-outline"
          >添加其它类型</el-button
        >
        <el-button
          v-if="canEdit"
          @click="submitData"
          type="primary"
          icon="el-icon-circle-check"
          >提交</el-button
        >
        <el-button @click="downloadAllAttachment">下载全部附件</el-button>
      </div>
    </template>
    <span class="upload-top-text">
      支持格式：{{ fileTypes.toString() }}，单个文件不超过{{ fileSzie }}MB
    </span>
    <div class="drag" ref="dragMain">
      <!-- 上方待拖入文件区 -->
      <div
        v-for="(ditem, dindex) in locationOptions"
        :key="dindex"
        class="drag-main"
      >
        <!-- 上方选择框和提示文字 -->
        <div class="drag-area-top">
          <div class="top-left">
            <el-select
              v-model="ditem.type"
              :disabled="(canEdit && dindex < 2 && !canDeletOld) || !canEdit"
              placeholder="请选择"
            >
              <el-option
                v-for="sel in titleSelectsData"
                :key="sel.value"
                :label="sel.label"
                :value="sel.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="ditem.selectValue"
              :disabled="(canEdit && dindex < 2 && !canDeletOld) || !canEdit"
              placeholder="请选择"
            >
              <el-option
                v-for="(sel, ind) in hadnleValueSelectsObj(ditem.type)"
                :key="ind + 66"
                :label="sel.label"
                :value="sel.value"
              ></el-option>
            </el-select>
          </div>
          <el-button
            v-if="ditem.isOther && canEdit"
            type="text"
            icon="el-icon-delete"
            @click="deleteOther(dindex)"
            >删除分类</el-button
          >
          <span v-else class="top-right">
            <span v-if="ditem.tipsRequire">*</span>
            {{ ditem.rightTips }}
          </span>
        </div>
        <!-- 文件区 -->
        <div v-if="!isClickUpload" class="drag-in-area">
          <draggable
            v-model="ditem.urls"
            :options="{
              group: { name: 'itxst', pull: true, put: true },
            }"
          >
            <transition-group v-if="ditem.urls.length" class="transition-group">
              <FileIcon
                v-for="(file, index) in ditem.urls"
                :key="file.id"
                :suffix="file.suffix"
                :fileName="file.name"
                @deletFile="deleteDargInFile(dindex, index, file)"
                @click.native="preView(ditem.urls, index)"
              ></FileIcon>
            </transition-group>
            <transition-group
              v-else
              class="transition-group transition-group-none"
            >
              <i class="el-icon-upload"></i>
              <span key="none-text">{{
                canEdit ? "请把下方对应文件拖拽到此" : "--"
              }}</span>
            </transition-group>
          </draggable>
        </div>

        <el-upload
          v-if="isClickUpload"
          class="drag-in-area"
          action="#"
          multiple
          drag
          :show-file-list="false"
          :http-request="() => {}"
          :on-change="
            (file, fileList) => {
              uploadChange(file, fileList, 1, dindex);
            }
          "
        >
          <div v-if="ditem.urls.length" class="transition-group">
            <FileIcon
              v-for="(file, index) in ditem.urls"
              :key="file.id"
              :suffix="file.suffix"
              :fileName="file.name"
              @deletFile="deleteDargInFile(dindex, index, file)"
              @click.native.stop="preView(ditem.urls, index)"
            ></FileIcon>
          </div>
          <div v-else class="transition-group transition-group-none">
            <i class="el-icon-upload el-icon-upload2"></i>
            <span>{{
              canEdit ? "请把文件拖拽到此或者点击此处上传文件" : "--"
            }}</span>
          </div>
        </el-upload>
      </div>
    </div>
    <!-- 下方文件上传区域 -->
    <div class="botton-file-area" v-if="canEdit && !isClickUpload">
      <!-- 提示文字和上传按钮 -->
      <div class="upload-top">
        <div class="upload-top-left">
          <span class="upload-top-text1">本地上传</span>
          <span class="upload-top-text2">
            （支持格式：{{ fileTypes.toString() }}，单个文件不超过{{
              fileSzie
            }}MB）
          </span>
        </div>
        <el-upload
          class="upload-top-right"
          action="#"
          :show-file-list="false"
          multiple
          :limit="fileLimit"
          :on-exceed="handleExceed"
          :on-change="uploadChange"
          :http-request="fileUpload"
        >
          <span class="limit-text">({{ fileCount }}/{{ fileLimit }})</span>
          <el-button class="upload-btn" icon="el-icon-upload" type="primary"
            >点击选择文件</el-button
          >
        </el-upload>
      </div>
      <!-- 文件展示区域 -->
      <div class="file-show">
        <draggable
          v-model="localFileList"
          :options="{
            group: { name: 'itxst', pull: 'clone', put: false },
          }"
        >
          <transition-group style="height: 100%">
            <FileIcon
              v-for="(file, index) in localFileList"
              :key="file.id"
              :suffix="file.suffix"
              :fileName="file.name"
              @deletFile="deletUploadFile(index)"
            ></FileIcon>
          </transition-group>
        </draggable>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="preShow" width="1160px" append-to-body>
        <template #footer>
          <el-button @click="downloadThisFile">下载</el-button>
        </template>
        <template #title> 文件预览与下载 </template>
        <div class="pre-main">
          <!-- 左箭头 -->
          <div class="arrow">
            <i @click="preNext()" class="el-icon-caret-left icon"></i>
          </div>
          <!-- 预览内容 -->
          <div class="pre-content">
            <iframe
              ref="preIframe"
              width="100%"
              height="500px"
              :src="preUrl"
            ></iframe>
          </div>
          <!-- 右箭头 -->
          <div class="arrow">
            <i @click="preNext('next')" class="el-icon-caret-right icon"></i>
          </div>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script>
import { nanoid } from "nanoid";
import { OSSDirectPass } from "@/api/oss.js";
import draggable from "vuedraggable";
import FileIcon from "./FileIcon.vue";
import { downloadZIP } from "@/utils/index";
export default {
  name: "DragMultipleUpload",
  components: { draggable, FileIcon },
  props: {
    title: {
      type: String,
      default: "上传资料",
    },
    //文件最大上传数量
    fileLimit: {
      type: Number,
      default: 20,
    },
    //拖入框配置
    dargInOptions: {
      type: Array,
      default: () => {
        return [
          {
            // type: "dqzl", //类型名字（返回）
            type: "khzj", //类型名字（返回）
            selects: [
              { label: "身份证", value: 1 },
              { label: "统一社会信用证", value: 2 },
              { label: "户口本", value: 3 },
              { label: "出生证", value: 4 },
              { label: "港澳通行证", value: 5 },
              { label: "外国护照", value: 6 },
              { label: "驾驶证", value: 7 },
              { label: "军官证", value: 8 },
              { label: "士兵证", value: 9 },
              { label: "军官离退休证", value: 10 },
              { label: "中国护照", value: 11 },
              { label: "异常身份证", value: 12 },
              { label: "台湾通行证", value: 13 },
              { label: "回乡证", value: 14 },
              { label: "旅行证", value: 15 },
              { label: "居留证件", value: 16 },
              { label: "港澳居民居住证", value: 17 },
              { label: "外国人永久居留身份证", value: 18 },
            ],
            selectValue: 1,
            leftTitle: "客户证件",
            rightTips: "出单必录",
            tipsRequire: true,
            isOther: false,
            urls: [],
          },
          // {
          //   type: "tbzlqr",
          //   selects: [
          //     { label: "签章投保单", value: 1 },
          //     { label: "电子签章投保单", value: 2 },
          //   ],
          //   leftTitle: "投保确认资料",
          //   rightTips: "出单必录",
          //   tipsRequire: true,
          //   isOther: false,
          // },
          {
            type: "qcxzl",
            selectValue: 1,
            selects: [{ label: "投保资产清单", value: 1 }],
            leftTitle: "企财险资料",
            rightTips: "出单必录",
            tipsRequire: true,
            isOther: false,
            urls: [],
          },
        ];
      },
    },
    otherOption: {
      type: Object,
      default: () => {
        return {
          selects: [
            { label: "倒签资料", value: 1 },
            { label: "文本保单或批单", value: 2 },
            { label: "其他", value: 3 },
          ],
          leftTitle: "请选择资料",
          isOther: true,
        };
      },
    },
    //支持的文件后缀名
    fileTypes: {
      type: Array,
      default: () => ["doc", "docx", "pdf", "xlsx", "jpg", "png", "pptx"],
    },
    //单个文件最大大小(M)
    fileSzie: {
      type: Number,
      default: 2,
    },
    show: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    dataBackShow: {
      type: Array,
      default: () => [],
    },
    dataEcho: {
      type: [Object, Array],
      default: () => {},
    },
    canDeletOld: {
      type: Boolean,
      default: true,
    },
    isClickUpload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      preShow: false,
      preUrl: "",
      preUrls: [],
      preIndex: 0,
      dialogVisible: false,
      //本地文件上传列表
      localFileList: [],
      //要提交的数据
      sbumitData: [],
      locationOptions: [],
      titleSelectsData: [
        { label: "客户证件", value: "khzj" },
        { label: "倒签资料", value: "dqzl" },
        { label: "企财险资料", value: "qcxzl" },
        { label: "文本保单或批单", value: "wbbdhpd" },
        { label: "投保确认单", value: "offline" },
      ],
      valueSelectsObj: {
        khzj: [
          { label: "身份证", value: 1 },
          { label: "统一社会信用证", value: 2 },
          { label: "户口本", value: 3 },
          { label: "出生证", value: 4 },
          { label: "港澳通行证", value: 5 },
          { label: "外国护照", value: 6 },
          { label: "驾驶证", value: 7 },
          { label: "军官证", value: 8 },
          { label: "士兵证", value: 9 },
          { label: "军官离退休证", value: 10 },
          { label: "中国护照", value: 11 },
          { label: "异常身份证", value: 12 },
          { label: "台湾通行证", value: 13 },
          { label: "回乡证", value: 14 },
          { label: "旅行证", value: 15 },
          { label: "居留证件", value: 16 },
          { label: "港澳居民居住证", value: 17 },
          { label: "外国人永久居留身份证", value: 18 },
        ],
        dqzl: [
          { label: "出单通知", value: 1 },
          { label: "暂保申请", value: 2 },
          { label: "未出险证明", value: 3 },
          { label: "客户的付款凭证说明", value: 4 },
          { label: "监管文件", value: 5 },
          { label: "预约协议", value: 6 },
          { label: "统保协议", value: 7 },
        ],
        qcxzl: [{ label: "投保资产清单", value: 1 }],
        wbbdhpd: [
          { label: "文本保单", value: 1 },
          { label: "文本批单", value: 2 },
        ],
        offline: [{ label: " ", value: 0 }],
      },
      defaultValue: {
        leftTitle: "",
        type: "",
        urls: [],
        selectValue: 1,
      },
    };
  },
  computed: {
    fileCount() {
      return this.localFileList.length;
    },
  },
  watch: {
    //处理参数形成内部使用配置
    dargInOptions: {
      immediate: true,
      handler(n) {
        this.locationOptions = n.map((item, index) => {
          return {
            ...item,
            urls: [], //该拖入框的数组(返回)
            selectValue: 1, //下拉框选择的类型(返回)
          };
        });
      },
    },
    dialogVisible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      if (this.dataEcho instanceof Array) {
        if (!this.dataEcho.length) {
          this.dealData(this.dargInOptions);
        } else {
          let localFileList = [];
          this.locationOptions = this.dataEcho.map((val) => {
            val.urls
              ? val.urls.map((val2) => {
                  let flag = localFileList.findIndex(
                    (item) => item.url == val2.url
                  );
                  if (flag === -1) {
                    localFileList.push({ ...val, isNew: true });
                  }
                })
              : "";
            return val;
          });
        }
      } else if (n && JSON.stringify(this.dataEcho) != "{}") {
        let nameList = [];
        let localFileList = [];
        this.locationOptions = [];
        for (const key in this.dataEcho) {
          this.dataEcho[key].urls
            ? this.dataEcho[key].urls.map((val) => {
                let flag = localFileList.findIndex(
                  (item) => item.url == val.url
                );
                if (flag === -1) {
                  localFileList.push({ ...val, isNew: true });
                }
              })
            : "";
          // localFileList.push(...this.dataEcho[key].urls);
          nameList.push(
            ...this.dataEcho[key].urls.map((val) => JSON.stringify(val))
          );
          this.locationOptions.push({
            ...this.defaultValue,
            ...this.dataEcho[key],
          });
        }
        this.localFileList = localFileList;
      }
      this.dialogVisible = n;
    },
    dataBackShow: {
      handler() {
        this.dealData(this.dargInOptions);
      },
    },
  },
  methods: {
    //删除上传文件
    deletUploadFile(index) {
      this.localFileList.splice(index, 1);
    },
    handleExceed() {
      this.$message({
        message: "上传文件数量过多！",
        type: "warning",
      });
    },
    //获取后缀名
    getSuffix(fileName) {
      let arr = fileName.split(".");
      if (arr.length) {
        return arr[arr.length - 1];
      }
      return "";
    },
    //处理上传文件
    async uploadChange(file, fileList, type = 0, index) {
      let suffix = this.getSuffix(file.name);
      if (this.fileTypes.indexOf(suffix) == -1) {
        this.$message({
          message: "不支持该文件格式",
          type: "warning",
        });
        return;
      }
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > this.fileSzie) {
        this.$message({
          message: `上传的文件大于${this.fileSzie}MB`,
          type: "warning",
        });
        return;
      }
      let fileUrl = await OSSDirectPass(file);
      if (fileUrl) {
        if (type == 1) {
          this.locationOptions[index].urls.push({
            suffix,
            url: fileUrl,
            name: file.name,
            id: file.uid,
            isNew: true,
          });
        } else {
          this.localFileList.push({
            suffix,
            url: fileUrl,
            name: file.name,
            id: file.uid,
            isNew: true,
          });
        }
      }
    },
    fileUpload() {},
    //处理传入的其他类型参数
    addOtherType() {
      this.locationOptions.push({
        ...this.otherOption,
        // type: `other_${ nanoid() }`,
        type: "",
        urls: [],
        selectValue: "",
      });
      this.$nextTick(() => {
        let drarMain = this.$refs[`dragMain`];
        drarMain.scrollTo({ top: drarMain.scrollHeight, behavior: "smooth" });
      });
    },
    //删除其他分类
    deleteOther(index) {
      this.locationOptions.splice(index, 1);
    },
    //获取各个拖拽框的文件数据
    getDargFilesData() {
      let fileData = this.locationOptions.map((item) => {
        return {
          urls: item.urls,
          selectValue: item.selectValue,
          type: item.type,
        };
      });
      return fileData;
    },
    //数据提交
    submitData() {
      let fileDataList = this.getDargFilesData();
      if (
        fileDataList.every((item) => {
          return item.selectValue != "";
        })
      ) {
        this.$emit("submitData", this.getDargFilesData());
      } else {
        this.$message({
          message: "请选择类型！",
          type: "warning",
        });
      }
    },
    //删除拖入的文件
    deleteDargInFile(dindex, index, file) {
      if (this.canEdit) {
        if (this.canDeletOld) {
          this.locationOptions[dindex].urls.splice(index, 1);
          return;
        } else {
          if (file.isNew) {
            this.locationOptions[dindex].urls.splice(index, 1);
          } else {
            this.$message({
              message: "不允许删除原有文件！",
              type: "warning",
            });
          }
          return;
        }
      }
      this.$message({
        message: "不允许删除！",
        type: "warning",
      });
    },
    //文件预览
    preView(urls, indx) {
      if (this.canEdit) return;
      this.preShow = true;
      this.preUrl = urls[indx].url;
      this.preUrls = urls;
      this.preIndex = indx;
    },
    preNext(type) {
      if (type == "next") {
        this.preIndex = ++this.preIndex;
        if (this.preIndex >= this.preUrls.length) {
          this.preIndex = this.preUrls.length;
        }
        this.preUrl = this.preUrls[this.preIndex].url;
      } else {
        this.preIndex = --this.preIndex;
        if (this.preIndex <= 0) {
          this.preIndex = 0;
        }
        this.preUrl = this.preUrls[this.preIndex].url;
      }
    },
    //处理回显数据
    dealData(n) {
      if (this.dataBackShow.length > n.length) {
        this.locationOptions = [];
        //长度差值，超出全部当作其他
        let c = this.dataBackShow.length - n.length;
        for (let i = n.length; i < this.dataBackShow.length; i++) {
          this.locationOptions.push({
            ...this.otherOption,
            urls: this.dataBackShow[i].urls, //该拖入框的数组(返回)
            id: this.dataBackShow[i].id, //id
            selectValue: this.dataBackShow[i].selectValue, //下拉框选择的类型(返回)
          });
        }
      } else {
        this.locationOptions = n.map((item, index) => {
          return {
            ...item,
            urls: item.urls, //该拖入框的数组(返回)
            id: item.id, //id
            selectValue: item.selectValue, //下拉框选择的类型(返回)
          };
        });
      }
    },
    hadnleValueSelectsObj(v) {
      if (v) {
        //console.log(this.valueSelectsObj[v]);
        return this.valueSelectsObj[v];
      }
      return [];
    },
    downloadAllAttachment() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      this.locationOptions.forEach((item) => {
        item.urls.forEach((urlitem) => {
          if (urlitem.url) {
            arr.push({
              name: `${urlitem.name}.${urlitem.suffix}`,
              url: urlitem.url,
            });
          }
        });
      });
      if (arr.length) {
        downloadZIP(arr, "附件")
          .then((res) => {
            loading.close();
          })
          .catch((err) => {
            loading.close();
          });
      } else {
        this.$message.warning("暂无可下载的附件！");
        loading.close();
      }
    },
    downloadThisFile() {
      downloadZIP(
        [
          {
            name: this.preUrls[this.preIndex].name,
            url: this.preUrls[this.preIndex].url,
          },
        ],
        this.preUrls[this.preIndex].name
      )
        .then((res) => {
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-upload-dragger {
  width: 508px;
  min-height: 235px;
  border: none;
  background-color: #f3f8ff;
  overflow: auto;
}
::v-deep .el-dialog__body {
  padding-bottom: 1px;
  padding-top: 0px;
  margin-top: 10px;
}
::v-deep .el-dialog__footer {
  padding: 0;
}

::v-deep .el-input {
  width: 98%;
}
.drag {
  display: flex;
  flex-wrap: wrap;
  height: 350px;
  overflow-y: auto;
}
.bottom-btn {
  //   box-shadow: 0px -5px 5px #888888;
  box-shadow: 0px -3px 6px 1px rgb(0 0 0 / 16%);
  height: 80px;
  padding: 0 28px;
  border-radius: 8px;
  border: 1px solid #f5f0f0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
.transition-group {
  min-height: 234px;
  width: 500px;
  display: flex;
  display: inline-block;
}
.transition-group-none {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #cccccc;
}
.drag-main {
  width: 512px;
  margin-left: 28px;
  margin-top: 24px;
  .drag-area-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      > span {
        margin-right: 12px;
      }
    }
    .top-right {
      font-size: 12px;
      font-weight: 300;
      color: #666666;
      > span {
        color: #c94242ff;
      }
    }
  }
  .drag-in-area {
    overflow: auto;
    background: #f3f8ff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px dashed #d1e3fe;
    height: 240px;
    width: 512px;
    margin-top: 12px;
    box-sizing: content-box;
  }
}

.botton-file-area {
  display: column;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(120, 120, 120, 0.16);
  border-radius: 0px 0px 8px 8px;
  opacity: 1;
  .upload-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0 0 28px;
    .upload-top-left {
      color: #333333;
      .upload-top-text1 {
        font-size: 14px;
        font-weight: bold;
      }
      .upload-top-text2 {
        font-size: 12px;
        font-weight: 200;
      }
    }
    .upload-top-right {
      margin-right: 28px;
      .limit-text {
        font-size: 12px;
        font-weight: 300;
        color: #333333;
        margin-right: 8px;
      }
      .upload-btn {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .file-show {
    min-height: 100px;
  }
}
.pre-main {
  display: flex;
  align-items: center;
  .arrow {
    width: 45px;
  }
  .pre-content {
    width: 90%;
  }
  .icon {
    font-size: 40px;
  }
  .icon:hover {
    font-size: 45px;
  }
}
.upload-top-text {
  font-size: 12px;
  font-weight: 200;
  padding-left: 30px;
}
.el-icon-upload2 {
  font-size: 30px;
  color: #4278c9ff;
  margin: 0 10px;
}
</style>
